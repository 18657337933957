import axios from 'axios'
import { ElMessage } from 'element-plus'
import { diffTokenTime } from '@/utils/auth'
import store from '@/store'
const service = axios.create({
  // baseURL: 'http://127.0.0.1:19999/api/manage/v1',
  // baseURL: 'https://api.mojiter.com/api/manage/v1',
  // baseURL: 'https://api.hengdu.pro/api/manage/v1',
  baseURL: 'https://api.quantifytoken.com/api/manage/v1',
  timeout: 5000
})

service.interceptors.request.use(
  (config) => {
    if (localStorage.getItem('token')) {
      if (diffTokenTime()) {
        store.dispatch('app/logout')
        return Promise.reject(new Error('token 失效了'))
      }
    }
    config.headers.token = localStorage.getItem('token')
    return config
  },
  (error) => {
    return Promise.reject(new Error(error))
  }
)

service.interceptors.response.use(
  (response) => {
    const { data, status } = response
    if (status === 200) {
      return data
    } else {
      ElMessage.error(data.message)
      return Promise.reject(new Error(data.message))
    }
  },
  (error) => {
    console.log(error.response)
    if (error.response.data.message === '签名信息错误') {
      ElMessage.error(error.response.data)
      store.dispatch('app/logout')
    } else if (error.response) {
      ElMessage.error(error.response.data)
      return Promise.reject(new Error(error.response.data))
    } else {
      return ElMessage.error('请求服务器失败，请检查网络连接')
    }
  }
)

export default service
