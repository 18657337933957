import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/login')
  },
  {
    path: '/',
    name: '/',
    component: () => import('../layout'),
    redirect: '/users',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/views/dashboard/index.vue')
      },
      {
        path: 'users',
        name: 'users',
        component: () => import('@/views/users/index.vue')
      },
      {
        path: 'fund_record',
        name: 'fund_record',
        component: () => import('@/views/fund_record/index.vue')
      },
      {
        path: 'investment_record',
        name: 'investment_record',
        component: () => import('@/views/investment/index.vue')
      },
      {
        path: 'withdraw_record',
        name: 'withdraw_record',
        component: () => import('@/views/withdraw/index.vue')
      },
      {
        path: 'order',
        name: 'order',
        component: () => import('@/views/order/index.vue')
      },
      {
        path: 'transaction',
        name: 'transaction',
        component: () => import('@/views/transaction/index.vue')
      },
      {
        path: 'subscription',
        name: 'subscription',
        component: () => import('@/views/config/subscription/index.vue')
      },
      {
        path: 'investment',
        name: 'investment',
        component: () => import('@/views/config/investment/index.vue')
      },
      {
        path: 'address_config',
        name: 'address_config',
        component: () => import('@/views/config/address_config.vue')
      },
      {
        path: 'withdrawal_config',
        name: 'withdrawal_config',
        component: () => import('@/views/config/withdrawal_config.vue')
      },
      {
        path: 'user_experience_config',
        name: 'user_experience_config',
        component: () => import('@/views/config/user_experience_config.vue')
      },
      {
        path: 'fwqzt',
        name: 'fwqzt',
        component: () => import('@/views/system/index.vue')
      },
      {
        path: 'investment_earn',
        name: 'investment_earn',
        component: () => import('@/views/investment/investment_earn.vue')
      },
      {
        path: 'update_password',
        name: 'update_password',
        component: () => import('@/layout/headers/components/update_password.vue')
      }
    ]
  }
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
