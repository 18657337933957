export default {
  menus: {
    '/': '首页',
    home: '首页',
    dashboard: '仪表盘',
    users: '用户列表',
    fund_record: '资金记录',
    investment_record: '托管记录',
    investment_earn: '释放明细',
    withdraw_record: '提现记录',
    order: '订单列表',
    transaction: '交易记录',
    subscription: '机器人套餐',
    investment: '托管套餐',
    address_config: '地址配置',
    withdrawal_config: '提现手续费',
    user_experience_config: '体验天数',
    fwqzt: '服务器状态'
  },
  login: {
    title: '用户登录',
    btnTitle: '登录'
  },
  dialog: {
    deleteTitle: '确定要删除用户',
    delete: '确定删除?'
  },
  table: {
    username: '姓名',
    email: '邮箱',
    mobile: '手机',
    role_name: '角色',
    mg_state: '状态',
    create_time: '创建时间',
    action: '操作',
    search: '搜索',
    adduser: '添加用户',
    add_package: '添加套餐',
    placeholder_package: '请输入套餐名称',
    placeholder_order_id: '请输入订单id',
    placeholder_user_address: '请输入用户钱包地址',
    placeholder_parent_id: '请输入上级id',
    placeholder: '请输入搜索的用户姓名'
  },
  message: {
    updeteSuccess: '更新成功'
  },
  driver: {
    doneBtnText: '完成',
    closeBtnText: '关闭',
    nextBtnText: '下一步',
    prevBtnText: '上一步',
    guideBtn: '引导按钮',
    hamburgerBtn: '汉堡按钮',
    fullScreen: '全屏按钮'
  }
}
