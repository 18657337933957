export default {
  menus: {
    '/': 'home',
    home: 'home',
    dashboard: '仪表盘',
    users: 'users list',
    fund_record: '资金记录',
    investment_record: '托管记录',
    investment_earn: '释放明细',
    withdraw_record: '提现记录',
    order: 'order list',
    transaction: '交易记录',
    subscription: '机器人套餐',
    investment: '托管套餐',
    address_config: '地址配置',
    withdrawal_config: '提现手续费',
    user_experience_config: '体验天数',
    fwqzt: '服务器状态'
  },
  login: {
    title: 'user login',
    btnTitle: 'login'
  },
  dialog: {
    deleteTitle: 'Are you sure you want to delete the user '
  },
  table: {
    username: 'username',
    email: 'email',
    mobile: 'mobile',
    role_name: 'role name',
    mg_state: 'state',
    create_time: 'create_time',
    action: 'action',
    search: 'search',
    adduser: 'add user',
    add_package: '添加套餐',
    placeholder_package: '请输入套餐名称',
    placeholder: 'Please enter a user name to search for'
  },
  message: {
    updeteSuccess: 'update successfully'
  },
  driver: {
    doneBtnText: 'done',
    closeBtnText: 'close',
    nextBtnText: 'next',
    prevBtnText: 'prev',
    guideBtn: 'guidebtn',
    hamburgerBtn: 'hamburgerBtn',
    fullScreen: 'fullScreen'
  }
}
